@import url('https://fonts.googleapis.com/css2?family=Merriweather&family=Montserrat&family=Sacramento&family=Titillium+Web:wght@300;400;600&display=swap');

*{
    padding: 0;
    margin: 0;
    box-sizing: border-box;
    font-family: 'Titillium Web', sans-serif;
}
:root{
    --mainColor: black;
    --mainColorLight: rgb(43, 40, 40);
    --secondaryColor: white;
    --textColor: rgb(6, 183, 6);
}

header {
    position: fixed;
    top: 0;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    z-index: 1;
    height: 80px;
    padding: 0 2rem;
    background-color: var(--mainColor);
    color: var(--textColor);
}

nav a{
    margin: 0 2rem;
    color: var(--textColor);
    text-decoration: none;
}
nav a:hover{
    color: var(--secondaryColor);
}

header .nav-btn{
    padding: 5px;
    cursor: pointer;
    background-color: transparent;
    border: none;
    outline: none;
    color: var(--textColor);
    visibility: hidden;
    opacity: 0;
    font-size: 1.8rem;
}
@media only screen and (max-width: 1024px) {
    header .nav-btn{
        visibility: visible;
        opacity: 1;
    }
    header nav{
        position: fixed;
        top: 0;
        left: 0;
        height: 100%;
        width: 100%;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        gap: 1.5rem;
        background-color: var(--mainColor);
        transition: 1s;
        transform: translateY(-100vh);
    }

    header .responsive_nav{
        transform: none;
    }
    nav .nav-close-btn{
        position: absolute;
        top: 2rem;
        right: 2rem;
    }

    nav a{
        font-size: 1.5rem;
    }
    
    
}


.logo-a {
    &:link{
        text-decoration: none;
    }
    &:visited{
        text-decoration: none;
    }
    &:active{
        text-decoration: none;
    }
    color: var(--textColor);
    font-size: 1.2rem;
    font-weight: bold;
}